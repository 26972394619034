@import "styles/mixins";

.signup {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  text-wrap:balance;
  label {
    visibility: hidden;
    font-size: 0px;
  }
  label.required:after {
   visibility: visible;
   font-size:12px;
  }
  @include smallScreen(){
    text-align: center;
  }
}

.title {
  content: attr(aria-label);
  color: var(--clr-primary-300);
  font-weight: 700;
  text-transform: capitalize;
}

.subtitle {
  font-size: 0.875rem !important;
}

.inputContainer {
  display: flex;
  padding: 10px 0px;
  input[type="email"],
  input[type="text"] {
    padding: 10px;
    background: var(--clr-neutral-100);
    border-radius: 5px 0 0 5px;
    border: 1px solid var(--clr-neutral-300);
    outline: none;
    border-right: none;
    width: 100%;
    height: 100%;
  }
  input[type="submit"] {
    width: 100%;
    height: 100%;
    border-radius: 0 5px 5px 0;
  }
  @include smallScreen(){
    justify-content: center;
  }
}

.fullInputContainer {
  padding: 10px 0px;
  > div {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    margin-bottom: 1rem;
  }
  span {
    text-align: left;
    font-size:0.875rem;
    margin-top:0.5rem;
  }
  input[type="email"],
  input[type="text"] {
    padding: 10px;
    background: var(--clr-neutral-100);
    border-radius: 5px;
    border: 1px solid var(--clr-neutral-300);
    outline: none;
    width: 100%;
    height: 100%;
  }
  input[type="submit"] {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }

  
  // .required::after {
  //     color:red;
  //     content:"Required";
  //     width:100px;
  //     height:100px;
  //   }
  
}

.dates {
  display: flex;
  gap: 1rem;
  align-items: center;
  // flex-direction: column;
}
